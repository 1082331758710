body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "roboto";
  src: local("roboto"), url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "mw";
  src: local("mw"), url(./assets/fonts/Merriweather-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "mw-bold";
  src: local("mw-bold"), url(./assets/fonts/Merriweather-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "mw-liht";
  src: local("mw-light"), url(./assets/fonts/Merriweather-Light.ttf) format("truetype");
}

body .slick-track {
  line-height: 0;
}
